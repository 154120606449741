<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-6 py-lg-7 pt-lg-8">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-4">
              <h1 class="text-white" style="font-size:35px">Document Management System</h1>
              <!--<p class="text-lead text-white">Use these awesome forms to login or create new account in your project for
                free.</p>-->
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <!-- <b-card-header class="bg-transparent pb-5"  >
              <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
              <div class="btn-wrapper text-center">
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a href="#" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </b-card-header> -->
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                      <p class="text-danger mb-0" v-show="model.status" v-text="model.status"></p>
                <small>Please sign in with credentials</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              v-model="model.email">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 8}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              v-model="model.password">
                  </base-input>

                  <b-form-checkbox v-model="model.rememberMe">Remember me</b-form-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <!-- <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>Forgot password?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'
  import axios from 'axios'
  //import loginFunctionMix from '@/assets/js/loginFunctionMix.js'
  import { mapWritableState ,mapStores, mapActions, mapState } from 'pinia'
  import { useLoginInfoStore } from '@/stores/login_info'

  export default {
    mixins: [],
    data() {
      return {
        model: {
          email: '',
          password: '',
          rememberMe: false,
          status: false,
        }
      };
    },
    methods: {
      ...mapActions(useLoginInfoStore, ['fetchLoginInformation']),
      onSubmit() {
        const authUser = {}
        const loginUser = {}
        const defaultDashboard = {}

        const postData = {
          grant_type : 'password',
          client_id : clientId,
          client_secret : clientSecret,
          username : this.model.email,
          password : this.model.password,
          scope : '*'
        }

        axios.post(LoginURL, postData )
          .then ( response => {
            if (response.status === 200)
            {
                authUser.access_token = response.data.access_token
                authUser.refresh_token = response.data.refresh_token
                window.localStorage.setItem('authUser', JSON.stringify(authUser))

                axios.get(apiDomain + 'setup/user.info', { headers: getHeader() })
                .then(response => {
                      // console.log(response)
                      loginUser.id = response.data.id
                      loginUser.role_id = response.data.role_id
                      loginUser.name = response.data.name
                      loginUser.email = response.data.email
                      loginUser.hod = response.data.hod
                      window.localStorage.setItem('loginUser', JSON.stringify(loginUser))
                      this.fetchLoginInformation()
                      //this.getLoginUserRoleAccess()
                    
                      this.$router.push('doc/documents')
                  })
            }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.model.status = "Invalid Email or Password !!!"
          } );
      },

    }
  };
</script>
